/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-3",
    "aws_cognito_identity_pool_id": "eu-west-3:61b909f3-5999-4e40-934f-b22ae87179eb",
    "aws_cognito_region": "eu-west-3",
    "aws_user_pools_id": "eu-west-3_7RSFeVEv7",
    "aws_user_pools_web_client_id": "e0o3r779nfoip7ulaqnjq6pga",
    "oauth": {
        "domain": "alix-prod.auth.eu-west-3.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://d14etv4bwxbfm8.cloudfront.net",
        "redirectSignOut": "https://d14etv4bwxbfm8.cloudfront.net/logout",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "alix-20220624161256-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "eu-west-3",
    "aws_content_delivery_url": "https://d14etv4bwxbfm8.cloudfront.net",
    "aws_dynamodb_all_tables_region": "eu-west-3",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "amplifyIdentityBrokerClientsTable-prod",
            "region": "eu-west-3"
        },
        {
            "tableName": "amplifyIdentityBrokerCodesTable-prod",
            "region": "eu-west-3"
        }
    ],
    "aws_user_files_s3_bucket": "alixd281fee019f84feeba00ed89ded333a0104156-prod",
    "aws_user_files_s3_bucket_region": "eu-west-3",
    "aws_cloud_logic_custom": [
        {
            "name": "alixConnectBrokerApi",
            "endpoint": "https://4xfcq91lfj.execute-api.eu-west-3.amazonaws.com/prod",
            "region": "eu-west-3"
        },
        {
            "name": "alixRestApi",
            "endpoint": "https://h56ayqmp9h.execute-api.eu-west-3.amazonaws.com/prod",
            "region": "eu-west-3"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://kgdmmwlozvfspnr4ey73bduxhq.appsync-api.eu-west-3.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-3",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
